<template>
<div>
    <Breadcrumbs main="Procurement" title="Vendor" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Vendor</h7>
                        </button>
                        <router-link to="/procurement/vendor/category">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>Vendor Category</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="categorySelected || statusSelected">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="categorySelected" :options="formattedCategories"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage" >
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>

                                        <template #cell(first_name)="data">
                                            <router-link :to="'/procurement/vendor/details/'+ data.item.id" class="link-style"> <span>{{ data.item.user.first_name + ' ' + data.item.user.last_name }}</span></router-link>
                                        </template>

                                        <template #cell(email)="data">
                                            <span>{{ data.item.user.email }}</span>
                                        </template>

                                        <template #cell(category)="data">
                                            <div v-if="data.item.category !== null && data.item.category.length > 0">
                                                <div v-for="label in data.item.category" :key="label.id">
                                                    <b-badge pill variant="success">{{ label.title }}</b-badge>
                                                </div>
                                            </div>
                                            <div v-else v-b-tooltip.hover title="Category" v-b-modal.modal-prevent-closing-edit @click="editItem(data.item)">
                                                <b-badge pill variant="secondary">Add Category</b-badge>
                                            </div>
                                        </template>

                                        <template #cell(company)="data">

                                            <!-- <small v-if="data.item.company"><span style="font-size: medium;">
                                                        <b-badge pill variant="dark">{{ data.item.company }}</b-badge>
                                                    </span></small> -->
                                            <p v-if="data.item.company" style="font-weight: 600;">{{ data.item.company }}</p>

                                        </template>

                                        <template #cell(address)="data">
                                            <span style="display: flex; flex-direction: column; gap: 8px;">
                                                <span>{{ data.item.address }}</span>
                                            </span>
                                        </template>

                                        <template #cell(status)="data">
                                            <span style="display: flex; flex-direction: column; gap: 8px;">
                                                <small @click="updateVendorStatus(data.item.id)" v-if="data.item.status == true" style="cursor: pointer;"><span style="font-size: medium;">
                                                        <b-badge pill variant="success" v-b-tooltip.hover title="Update Status">Active</b-badge>
                                                    </span></small>
                                                <small @click="updateVendorStatus(data.item.id)" v-if="data.item.status == false" style="cursor: pointer;"><span style="font-size: medium;">
                                                        <b-badge pill variant="danger" v-b-tooltip.hover title="Update Status">Inactive</b-badge>
                                                    </span></small>
                                            </span>
                                        </template>

                                        <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteVendor(data.item.id)" title="Delete"></feather>

                                                <feather @click="editItem(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div v-else>
                                <div class="loader-box">
                                    <div class="loader-10"></div>
                                </div>
                            </div>

                            <b-col class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Vendor" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="First Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.first_name" placeholder="Enter first name"></b-form-input>
            </b-form-group>
            <b-form-group label="Last Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="item.last_name" placeholder="Enter last name"></b-form-input>
            </b-form-group>

            <div class="form-group">
                <label>Category</label>
                <multiselect v-model="category" tag-placeholder="Add Category" placeholder="Search or add a category" label="title" :max="vendor_categories.length" track-by="id" :options="vendor_categories" :multiple="true" :taggable="true"></multiselect>
            </div>

            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="item.email" placeholder="Enter email address"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Vendor" @show="resetModal" @hidden="resetModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="First Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="edit_item.first_name" placeholder="Enter first name" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Last Name" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="edit_item.last_name" placeholder="Enter last name" disabled></b-form-input>
            </b-form-group>
            <div class="form-group">
                <label>Category</label>
                <multiselect v-model="category" tag-placeholder="Add Category" placeholder="Search or add a category" label="title" :max="vendor_categories.length" track-by="id" :options="vendor_categories" :multiple="true" :taggable="true"></multiselect>
            </div>
            <b-form-group label="Email" label-for="name-input" invalid-feedback="Email is required">
                <b-form-input id="name-input" v-model="edit_item.email" placeholder="Enter email address" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Phone No" label-for="name-input" invalid-feedback="Phone no is required">
                <b-form-input id="name-input" v-model="edit_item.phone" placeholder="Enter phone number"></b-form-input>
            </b-form-group>
            <b-form-group label="Company" label-for="name-input" invalid-feedback="Company is required">
                <b-form-input id="name-input" v-model="edit_item.company" placeholder="Enter company name"></b-form-input>
            </b-form-group>
            <b-form-group label="Address" label-for="name-input" invalid-feedback="Address is required">
                <b-form-input id="name-input" v-model="edit_item.address" placeholder="Enter address"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this vendor?</div>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'company',
                    label: 'Company',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Contact Person',
                    sortable: true
                },
                {
                    key: 'category',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'phone',
                    label: 'Phone No',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            category: [],
            item: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                category: [],
                company: '',
                address: ''
            },
            edit_item: {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                category: [],
                company: '',
                address: ''
            },
            filter: null,
            initialLoad: true,
            filterShow: false,
            statusSelected: '',
            categorySelected: '',
            status_list: [
                {
                    id: 1,
                    name: 'Active',
                    value: 'True'
                },
                {
                    id: 2,
                    name: 'Inactive',
                    value: 'False'
                }
            ],
            udate_value: null,
            delete_value: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.vendor.vendor,
            count: state => state.vendor.vendor_count,
            vendor_categories: state => state.vendor.vendor_categories,
            loading: state => state.vendor.vendorLoading,
            error: state => state.vendor.vendorError
        }),

        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.status_list.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedCategories() {
            return [
                {
                    value: '',
                    text: 'Select Category',
                    disabled: true
                },
                ...this.vendor_categories.map(status => ({
                    value: status.id,
                    text: status.title
                }))
            ];
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/procurement/vendor/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("vendor/fetchVendor", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/procurement/vendor/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("vendor/fetchVendor", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        filter(newVal, oldVal) {
            this.$store.dispatch("vendor/fetchVendor", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        statusSelected(newVal, oldVal) {
            this.$store.dispatch("vendor/fetchVendor", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected

            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        categorySelected(newVal, oldVal) {
            this.$store.dispatch("vendor/fetchVendor", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        }
    },

    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage
                }
            });
            this.$store.dispatch("vendor/fetchVendor", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("vendor/fetchVendor", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false
            });
        }

        this.$store.dispatch("vendor/fetchVendorCategory")
    },

    methods: {
        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.statusSelected ='',
            this.categorySelected = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        resetModal() {
            this.item.first_name = ''
            this.item.last_name = ''
            this.item.email = ''
            this.item.phone = ''
            this.item.company = ''
            this.item.address = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            this.category.map(item => this.item.category.push(item.id));
            this.$store.dispatch("vendor/addVendor", {
                item:this.item,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.item.first_name = ''
                    this.item.last_name = ''
                    this.item.email = ''
                    this.item.phone = ''
                    this.item.company = ''
                    this.item.address = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editItem(item) {
            this.edit_item.id = item.id
            this.edit_item.first_name = item.user.first_name
            this.edit_item.last_name = item.user.last_name
            this.edit_item.email = item.user.email
            this.edit_item.phone = item.phone
            this.edit_item.company = item.company
            this.edit_item.address = item.address
            this.category = item.category
        },

        handleEditSubmit() {
            this.category.map(item => this.edit_item.category.push(item.id));

            this.$store.dispatch("vendor/updateVendor", {
                id: this.edit_item.id,
                phone: this.edit_item.phone,
                company: this.edit_item.company,
                address: this.edit_item.address,
                category: this.edit_item.category,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.edit_item.phone = '',
                    this.edit_item.company = '',
                    this.edit_item.address = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteVendor(id) {
            this.delete_value = id
        },

        updateVendorStatus(id) {
            this.udate_value = id

            this.$store.dispatch("vendor/updateVendorStatus", {
                status_for_change: this.udate_value,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.udate_value = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("vendor/deleteVendor", {
                delete: this.delete_value,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.lead-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.table-width {
    width: 100%;
}

.label-width {
    width: 100%;
}

.label-small {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .label-small {
        width: 65%;
    }
}

@media screen and (min-width: 1100px) {
    .label-small {
        width: 50%;
    }
}

@media screen and (min-width: 1536px) {
    .lead-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .table-width {
        width: 75%;
    }

    .label-width {
        width: 25%;
    }

    .label-small {
        width: 100%;
    }
}

.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
