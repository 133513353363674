<template>
<div>
    <Breadcrumbs main="HR" submain="Asset Management" title="Assets" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content">
                            <router-link to="/hr/asset-management/asset/create">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Asset</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || start_date || end_date">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(name)="data">
                                        <span>
                                            <router-link :to="`/hr/asset-management/asset/details/${data.item.id}`" class="link-style">
                                                {{ data.item.name }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(warranty)="data">
                                        <span>
                                            {{ data.item.warranty }} {{ data.item.warranty_type }}
                                        </span>
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.status == 'PENDING'">
                                            <b-badge pill variant="warning">{{ data.item.status }}</b-badge>
                                        </span>
                                        <span v-if="data.item.status == 'APPROVED'">
                                            <b-badge pill variant="success">{{ data.item.status }}</b-badge>
                                        </span>
                                        <span v-if="data.item.status == 'RETURNED'">
                                            <b-badge pill variant="danger">{{ data.item.status }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(allocated_users)="data">
                                        <div v-if="data.item.allocated_users !== null && data.item.allocated_users.length > 0" v-b-modal.modal-prevent-closing @click="getAsset(data.item)">
                                            <div class="customers">
                                                <ul>
                                                    <ul v-for="(member, index) in data.item.allocated_users.slice(0,5)" :key="index">
                                                        <li v-if="member.asset_user.photo" class="d-inline-block" v-b-tooltip.hover :title="member.asset_user.full_name"><img class="img-30 rounded-circle" :src="host + member.asset_user.photo" alt="" data-original-title=""></li>
                                                        <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.asset_user.full_name"><img class="img-30 rounded-circle" src="../../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                    </ul>
                                                    <li class="d-inline-block mr-2" v-if="data.item.allocated_users.length > 5">
                                                        <p class="f-12">+ {{ data.item.allocated_users.length - 5 }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else @click="getAsset(data.item)" v-b-modal.modal-prevent-closing>
                                            <b-badge pill variant="primary">Allocate Employee</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteAsset(data.item.id)" title="Delete"></feather>
                                            <div>
                                                <router-link :to="`/hr/asset-management/asset/edit/${data.item.id}`">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                </router-link>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Allocate Employee Modal -->
    <b-modal id="modal-prevent-closing" hide-footer title="Allocate Employee">
        <div style="display: flex; flex-direction: column; gap: 15px;">
            <div v-if="this.asset.allocated_users !== null">
                <div class="row">
                    <div class="text-center col-sm-6 w-full" v-for="user in asset.allocated_users" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation @click="deleteAssetAllocation(user.id)" title="Delete">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.asset_user.first_name + ' ' + user.asset_user.last_name }}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>
            </div>

            <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
            <div class="d-flex justify-content-end ">
                <button @click="assignMember" class="btn btn-primary" style="width: 120px;">Allocate</button>
            </div>
        </div>
    </b-modal>

    <!-- Asset Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset?</div>
    </b-modal>

    <!-- Asset Allocate Delete Modal -->
    <b-modal id="modal-center-assetallocation" centered hide-header @ok="handleAssetDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset allocation?</div>
    </b-modal>
</div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import { myHost } from '../../../../_helpers/host';

export default {
    components: {
        Multiselect,
        Datepicker
    },

    data() {
        return {
            host: myHost.host,
            member: [],
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Asset',
                    sortable: true
                },
                {
                    key: 'asset_type.name',
                    label: 'Asset Type',
                    sortable: true
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'purchase_date',
                    label: 'Purchase Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'allocated_users',
                    label: 'Allocated User',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            initialLoad: true,
            start_date: null,
            end_date: null,
            statusSelected: '',
            filterShow: false,

            statuses: [
                {
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Returned',
                    value: 'RETURNED',
                }
            ],

            delete_value: null,
            asset: {},
            asset_id: null,
            asset_allocation_id: null,
            assignedMembers: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.asset.asset,
            count: state => state.asset.asset_count,
            user_list: state => state.employee.employee_list,
            loading: state => state.asset.submitLoading,
            error: state => state.asset.submitError
        }),

        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        }
    },

    watch: {
        filter(newVal, oldVal) {
            this.$store.dispatch("asset/fetchAsset", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                this.$store.dispatch("asset/fetchAsset", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter

                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                this.$store.dispatch("asset/fetchAsset", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        statusSelected(newVal, oldVal) {
            this.$store.dispatch("asset/fetchAsset", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/asset-management/asset/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("asset/fetchAsset", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/hr/asset-management/asset/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("asset/fetchAsset", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        }
    },

    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage
                }
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });

            this.$store.dispatch("asset/fetchAsset", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false; 
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("asset/fetchAsset", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false; 
            });
        }

        this.$store.dispatch("employee/fetchEmployeeList");
    },

    methods: {
        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.statusSelected ='',
            this.start_date = null,
            this.end_date = null
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        assignMember() {
            const idArray = this.assignedMembers.map(item => this.member.push({
                asset_user: item.id,
                asset: this.asset_id
            }));

            this.$store.dispatch("asset/assetAllocate", {
                member: this.member,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.assignedMembers = [],
                        this.member = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        deleteAsset(id) {
            this.delete_value = id
        },

        deleteAssetAllocation(id) {
            console.log(id)
            this.asset_allocation_id = id
        },

        getAsset(asset) {
            this.asset_id = asset.id,
                this.asset = asset
        },

        handleDeleteOk() {
            this.$store.dispatch("asset/deleteAsset", {
                delete: this.delete_value,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleAssetDeleteOk() {
            this.$store.dispatch("asset/deleteAssetAllocation", {
                id: this.asset_allocation_id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.asset_allocation_id = null
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-prevent-closing')
                    })

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-assetallocation')
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
