<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Category" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Category</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                             

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="filter">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!accountCategoryLoading && !accountCategoryError">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields"  :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>
                                    <template #cell(precedence)="data">

                                        <span @click="getPriority(data.item.precedence, data.item.id)">

                                            <span v-b-modal.modal-prevent-closing-priority>{{data.item.precedence}}</span>
                                        </span>

                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>

                                            <feather @click="editCategory(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Create Category -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Create Category" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="category.title" placeholder="Enter category title"></b-form-input>
            </b-form-group>
            <b-form-group label="Debit Logic" label-for="name-input" invalid-feedback="Debit Logic is required">
                <b-form-input id="name-input" v-model="category.debit_logic" placeholder="Enter debit logic"></b-form-input>
            </b-form-group>
            <b-form-group label="Credit Logic" label-for="name-input" invalid-feedback="Credit Logic is required">
                <b-form-input id="name-input" v-model="category.credit_logic" placeholder="Enter credit logic"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Category -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Category" @show="resetModal" @hidden="resetModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Title" label-for="name-input" invalid-feedback="Title is required">
                <b-form-input id="name-input" v-model="edit_category.title" placeholder="Enter category title"></b-form-input>
            </b-form-group>
            <b-form-group label="Debit Logic" label-for="name-input" invalid-feedback="Debit Logic is required">
                <b-form-input id="name-input" placeholder="Enter debit logic" v-model="edit_category.debit_logic"></b-form-input>
            </b-form-group>
            <b-form-group label="Credit Logic" label-for="name-input" invalid-feedback="Credit Logic is required">
                <b-form-input id="name-input" placeholder="Enter credit logic" v-model="edit_category.credit_logic"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
    <b-modal id="modal-prevent-closing-priority" ref="modal" title="Update Category Precedence" @show="resetModal" @hidden="resetModal" @ok="handlePriorityOk">
        <form ref="form" @submit.stop.prevent="handlePrioritySubmit">
            <b-form-group label="Serial" label-for="name-input">
                <b-form-input v-model="serial"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>

    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'debit_logic',
                    label: 'Debit Logic',
                    sortable: true
                },
                {
                    key: 'credit_logic',
                    label: 'Credit Logic',
                    sortable: true
                },
                {
                    key: 'precedence',
                    label: 'Precedence',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            items: [],
            serial: 0,
            category_id: 0,

            category: {
                title: '',
                debit_logic: '',
                credit_logic: ''
            },

            edit_category: {
                title: '',
                credit_logic: '',
                debit_logic: ''
            },
            initialLoad:true,
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState('account', ["accountCategories_withpg", "accountCategories_count", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
        
    },
    created() {
        this.$store.dispatch('account/fetchAccountCategoryWithPagination')
        this.items = this.accountCategories_withpg
        this.totalRows = this.items.length

    },
    watch: {
        accountCategories_withpg(oldValue, newValue) {

            this.items = this.accountCategories_withpg
            this.totalRows = this.items.length
        },
        filter(newVal, oldVal) {
            this.$store.dispatch("account/fetchAccountCategoryWithPagination", {
                perPage: this.perPage,
                currentPage: 1,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.accountCategories_count;
            });
        },
        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/category',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    },
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("account/fetchAccountCategoryWithPagination", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.accountCategories_count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/category',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("account/fetchAccountCategoryWithPagination", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    search_term: this.filter
                }).then(() => {
                    this.totalRows = this.accountCategories_count;
                });
            }
        }

    },
    mounted() {
        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });

            this.$store.dispatch("account/fetchAccountCategoryWithPagination", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.accountCategories_count;
                this.initialLoad = false
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("account/fetchAccountCategoryWithPagination", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.accountCategories_count;
                this.initialLoad = false
            });
        }
       
    },

    methods: {
        clearFilter() {
            this.filter = null
        },

        resetModal() {
            this.category.title = ''
            this.category.credit_logic = ""
            this.category.debit_logic = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("account/addAccountCategory", {
                category:this.category,
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.category.title = ""
                    this.category.credit_logic = ""
                    this.category.debit_logic = ""

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handlePriorityOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePrioritySubmit()
        },

        handlePrioritySubmit() {
            this.$store.dispatch("account/updateSerialCategory", {
                id: this.category_id,
                serial: this.serial,
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-priority')
            })
        },
        getPriority(serial, id) {
            this.category_id = id
            this.serial = serial
        },

        editCategory(item) {
            console.log(item)
            this.edit_category.id = item.id
            this.edit_category.title = item.title
            this.edit_category.credit_logic = item.credit_logic
            this.edit_category.debit_logic = item.debit_logic

        },

        // resetEditModal() {
        //     this.edit_item.name = ""
        //     this.edit_item.id = null
        //     this.edit_item.rate = null
        //     this.edit_item.description = ""
        // },

        handleEditSubmit() {
            this.$store.dispatch("account/updateAccountCategory", {
                id:this.edit_category.id,
                category:this.edit_category,
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_category.id = ""
                    this.edit_category.title = ""
                    this.edit_category.credit_logic = ""
                    this.edit_category.debit_logic = ""

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("account/deleteAccountCategory", {
                id:this.delete_value,
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
