<template>
<div>
    <Breadcrumbs main="Procurement" title="RFP" />
    <!-- Container-fluid starts--> 
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <router-link to="/procurement/rfp/create/new">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New RFP</h7>
                            </button>
                        </router-link>

                        <router-link to="/procurement/rfp/category/list">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>RFP Category</h7>
                            </button>
                        </router-link>
                    </div> 

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div> 

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected ||  start_date || end_date || activeSelected || categorySelected">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7> 
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="categorySelected" :options="formattedCategories"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="activeSelected" :options="formattedActive"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;"> 
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(rfp_id)="data">
                                        <span>
                                            <router-link :to="`/procurement/rfp/${data.item.id}`" class="link-style">
                                                {{ data.item.rfp_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(rfp_category)="data">
                                        <span v-if="data.item.rfp_category !== null">
                                            {{ data.item.rfp_category.title }}
                                        </span>
                                    </template>

                                    <template #cell(status)="row">
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'COMPLETED'">
                                            <b-badge pill variant="success" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'WORKING'">
                                            <b-badge pill variant="info" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="primary" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <!-- <template #cell(form_submit)="data">
                                    <span v-if="data.item.form_generated == true">
                                        <router-link :to="`/procurement/rfp/submit-form/${data.item.id}`">
                                            <b-button variant="secondary" style="width: 125px;">View Form</b-button>
                                        </router-link>
                                    </span>
                                </template> -->

                                    <template #cell(form_generated)="data">
                                        <div style="display: flex; flex-direction: row; gap: 5px; justify-content: center; align-items: center;">
                                            <div>
                                                <span v-if="data.item.form_generated == true">
                                                    <router-link :to="`/procurement/rfp/edit-form/${data.item.id}`">
                                                        <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                    </router-link>
                                                </span>
                                                <span v-else>
                                                    <router-link :to="`/procurement/rfp/create-form/${data.item.id}`">
                                                        <feather type="plus-circle" size="20px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Add"></feather>
                                                    </router-link>
                                                </span>
                                            </div>
                                            <div>
                                                <span v-if="data.item.form_generated == true">
                                                    <router-link :to="`/procurement/rfp/responses/${data.item.generated_form}`">

                                                        <feather type="message-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Response"></feather>

                                                    </router-link>
                                                </span>
                                            </div>
                                        </div>

                                    </template>

                                    <template #cell(active_status)="data">

                                        <div v-if="data.item.active == true" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success" v-b-tooltip.hover title="Toggle status">Active</b-badge>
                                        </div>
                                        <div v-if="data.item.active == false" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger" v-b-tooltip.hover title="Toggle status">Inactive</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRFP(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/procurement/rfp/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this RFP?</div>
    </b-modal>

    <!-- Update Status -->
    <b-modal id="modal-prevent-closing-status" ref="modal" title="Update RFP Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
        <form ref="form" @submit.stop.prevent="handleStatusSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses_for_update"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import js from '../../../auth/js';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'rfp_id',
                    label: 'RFP ID',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'rfp_category',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'deadline',
                    label: 'Deadline',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'form_generated',
                    label: 'Form',
                    sortable: true,
                    class: 'text-center'

                },

                {
                    key: 'active_status',
                    label: 'Active Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            start_date: null,
            end_date: null,
            categorySelected: '',
            statusSelected: '',
            activeSelected: '',
            filterShow: false,
            filter: null,
            rfp_category_id: '',
            delete_value: null,
            rfp_update_id: '',
            statuses_for_update: ['COMPLETED', 'WORKING', 'APPROVED'],
            statuses: [
                {
                    id: 1,
                    name: 'Completed',
                    value: 'COMPLETED'
                },
                {
                    id: 3,
                    name: 'Working',
                    value: 'WORKING',
                },
                {
                    id: 3,
                    name: 'Approved',
                    value: 'APPROVED'
                }
            ],
            active_list: [
                {
                    id: 1,
                    name: 'Active',
                    value: 'True'
                },
                {
                    id: 2,
                    name: 'Inactive',
                    value: 'False'
                }
            ],
            selectedStatus: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            lastScrollY: 0 
        };
    },

    computed: {
        ...mapState({
            items: state => state.rfp.rfp,
            count: state => state.rfp.count,
            rfp_category_list: state => state.rfp.rfp_category,
            loading: state => state.rfp.submitLoading,
            error: state => state.rfp.submitError
        }),

        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedActive() {
            return [
                {
                    value: '',
                    text: 'Select Active Status',
                    disabled: true
                },
                ...this.active_list.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedCategories() {
            return [
                {
                    value: '',
                    text: 'Select Category',
                    disabled: true
                },
                ...this.rfp_category_list.map(status => ({
                    value: status.id,
                    text: status.title
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory");

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage
                }
            });
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: this.currentPage
            }).then(() => {
                this.totalRows = this.count;
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("rfp/fetchRFP", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage
            }).then(() => {
                this.totalRows = this.count;
            });
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/procurement/rfp/list',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                }
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: newCurrentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.totalRows = this.count;
            });
        },

        perPage(newperPage) {
            this.$router.replace({
                path: '/procurement/rfp/list',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                }
            });
            this.perPage = newperPage
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: newperPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.totalRows = this.count;
            });
        },

        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected,
                    active: this.activeSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }else{
                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected,
                    active: this.activeSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected,
                    active: this.activeSelected
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }else{
                this.$store.dispatch("rfp/fetchRFP", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    status: this.statusSelected,
                    category: this.categorySelected,
                    active: this.activeSelected
                }).then(() => { 
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        statusSelected(newVal, oldVal) {
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },  

        filter(newVal, oldVal) {
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        }, 
          
        categorySelected(newVal, oldVal) {
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },   

        activeSelected(newVal, oldVal) {
            this.$store.dispatch("rfp/fetchRFP", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        }
    },

    methods: {
        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.statusSelected ='',
            this.start_date = null,
            this.end_date = null,
            this.categorySelected = '',
            this.activeSelected = ''
        },

        handleScroll(event) {
            // Calculate the new scroll position
            const scrollAmount = event.deltaY * this.scrollSpeedFactor;
            window.scrollBy({
                top: scrollAmount,
                behavior: 'smooth' // Smooth scroll
            });

            // Prevent default scrolling
            event.preventDefault();
        },

        resetModal() {
            this.selectedStatus = ''
        },

        getStatus(status, id) {
            this.rfp_update_id = id
            this.selectedStatus = status
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRFP(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("rfp/deleteRFP", {
                delete: this.delete_value,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        activeStatus(id) {
            this.$store.dispatch("rfp/toggleRFPStatus", {
                id: id,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.udate_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleStatusOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleStatusSubmit()
        },

        handleStatusSubmit() {
            // alert(JSON.stringify(this.rfp_update_id))
            // alert(JSON.stringify(this.selectedStatus))
            this.$store.dispatch("rfp/updateRfpStatus", {
                id: this.rfp_update_id,
                status_for_change: this.selectedStatus,
                page: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                status: this.statusSelected,
                category: this.categorySelected,
                active: this.activeSelected

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-status')
            })
        }
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
