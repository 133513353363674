import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'TICKET_UPDATE',
    smsDelete : 'TICKET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        ticket_list: [],
        my_ticket_list:[],
        refer_ticket_list:[],
        ticket_details:{},

        ticket_reply:[],
        count:null,
        pg_count:null,
        all_ticket:null,

        ticketLoading: false,
        ticketError: false,

        ticketReferLoading:false,
        ticketReferError:false,

        ticketReplyLoading:false,
        ticketReplyError:false,

        ticketSuccess:false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_TICKET (state, {results,pg_count}) {
            state.ticket_list = results;
            state.all_ticket = pg_count;
        },

        SET_MY_TICKET (state, {results,count}) {
            state.my_ticket_list = results;
            state.count = count;
        },

        SET_TICKET_DETAILS(state, detail) {
            state.ticket_details = detail;
        },

        SET_MY_REFER_TICKET (state, {results,pg_count}) {
            state.refer_ticket_list = results;
            state.pg_count = pg_count;
        },

        SET_TICKET_REPLY(state,reply){
            state.ticket_reply = reply;
        },

        TICKET_LOADING (state, loading) {
            state.ticketLoading = loading;
        },

        TICKET_ERROR (state, loading) {
            state.ticketError = loading;
        },

        TICKET_REPLY_LOADING (state, loading) {
            state.ticketReplyLoading = loading;
        },

        TICKET_REPLY_ERROR (state, loading) {
            state.ticketReplyError = loading;
        },

        TICKET_REFER_LOADING (state, loading) {
            state.ticketReferLoading = loading;
        },

        TICKET_REFER_ERROR (state, loading) {
            state.ticketReferError = loading;
        },

        TICKET_REPLY_LOADING (state, loading) {
            state.ticketReplyLoading = loading;
        },

        TICKET_REPLY_ERROR (state, loading) {
            state.ticketReplyError = loading;
        },

        TICKET_SUBMIT_SUCCESS (state, success) {
            state.ticketSuccess = success;
        }
    },

    actions: {
        async addTicket ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_ticket, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TICKET_SUBMIT_SUCCESS', true);
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },

        async addTicketMyEnd ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_ticket, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TICKET_SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyTicket')
                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },

        async fetchTicket ({ commit },payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{ 
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.raised_by) params.append('raised_by', payload.raised_by);
                if (payload.priority) params.append('priority', payload.priority);
               
                let url = V1API.get_ticket + '?' + params.toString();
                let result = await axios.get(url, config);
                
                const {results,pg_count} = result.data
                commit('SET_TICKET',  {results,pg_count});

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },

        async fetchTicketDetail ({ commit },payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_ticket_details+payload, config);
                let response = result.data
                commit('SET_TICKET_DETAILS', response.data);

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },

        async updateTicket({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.edit_ticket+payload.id,
                        {
                            subject: payload.subject,
                            refer_to: payload.refer_to
                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTicket');
                }
            )
            return response
        },


        async deleteTicket({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_ticket + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            if(payload.all){
                dispatch("fetchTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
            }
          
            return response
        },

        async updateTicketStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status_for_change, config).then(result => {
                    let data = result.data
                    return data
                })


                if(payload.all){
                    dispatch("fetchTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
                }
                else if(payload.from_my_refere){
                    dispatch("fetchMyRefreTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
                }
                else if(payload.from_details){
                    dispatch("fetchTicketDetail",payload.id)
                }
                
                return response

            } catch (e) {
                commit('TICKET_ERROR', true);
            } finally {
                commit('TICKET_LOADING', false);
            }
        },

        async updateTicketPriority({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.update_ticket_priority+ payload.id + "/" + payload.priority_for_change, config).then(result => {
                    let data = result.data
                    return data
                })
                if(payload.from_raise_ticket){
                    dispatch("fetchMyTicket",{currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
                }
                else if(payload.from_details){
                    dispatch("fetchTicketDetail",payload.id)
                }
                else if(payload.all){
                    dispatch("fetchTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
                }
                return response

            } catch (e) {
                commit('TICKET_ERROR', true);
            } finally {
                commit('TICKET_LOADING', false);
            }
        },

        async fetchMyTicket ({ commit },payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.search_term) params.append('search_term', payload.search_term);
         
               
                let url = V1API.get_my_ticket + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,count} = result.data
           
                commit('SET_MY_TICKET', {results,count});
                return result

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            finally {
                commit('TICKET_LOADING', false);
            }
        },

        async fetchMyRefreTicket ({ commit },payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let params = new URLSearchParams();
        
                if (payload.perPage) params.append('count', payload.perPage);
                if (payload.currentPage) params.append('page', payload.currentPage);
                if (payload.start_date) params.append('start_date', payload.start_date);
                if (payload.end_date) params.append('end_date', payload.end_date);
                if (payload.status) params.append('status', payload.status);
                if (payload.search_term) params.append('search_term', payload.search_term);
                if (payload.raised_by) params.append('raised_by', payload.raised_by);
                if (payload.priority) params.append('priority', payload.priority);
               
                let url = V1API.get_refer_ticket + '?' + params.toString();
                let result = await axios.get(url, config);

                const {results,pg_count} = result.data
              
                commit('SET_MY_REFER_TICKET', {results,pg_count});
                return result

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            finally {
                commit('TICKET_LOADING', false);
            }
        },

        async addTicketReply ({commit, dispatch}, payload) {
            commit('TICKET_REPLY_LOADING', true);
            commit('TICKET_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_ticket_reply, {

                    ticket: payload.ticket,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('TICKET_SUBMIT_SUCCESS', true);
                // dispatch ('fetchMyRefreTicket',{currentPage:payload.page,perPage:payload.perPage})

                return response

            }catch (e) {
                commit('TICKET_REPLY_ERROR', true);
            }finally {
                commit('TICKET_REPLY_LOADING', false);
            }
        },

        async fetchTicketReply ({ commit }, payload) {
            commit('TICKET_REPLY_LOADING', true);
            commit('TICKET_REPLY_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_ticket + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('TICKET_REPLY_ERROR', true);
            }
            finally {
                commit('TICKET_REPLY_LOADING', false);
            }
        },

        async ticketAddRefer ({commit, dispatch}, payload) {
            commit('TICKET_REFER_LOADING', true);
            commit('TICKET_REFER_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                dispatch("fetchMyRefreTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
           
                if(payload.myticket){
                    dispatch ('fetchMyTicket',{currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
                }
                else if(payload.detail){
                    dispatch ('fetchTicketDetail',payload.id)
                }
              
                else if(payload.all){
                    dispatch("fetchTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
                }
               
                return response

            }catch (e) {
                commit('TICKET_REFER_ERROR', true);
            }finally {
                commit('TICKET_REFER_LOADING', false);
            }
        },

        async ticketDeleteRefer ({commit, dispatch}, payload) {
            commit('TICKET_REFER_LOADING', true);
            commit('TICKET_REFER_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                dispatch("fetchMyRefreTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })

                if(payload.myticket){
                    dispatch ('fetchMyTicket',{currentPage:payload.page,perPage:payload.perPage,search_term:payload.search_term})
                }
                else if(payload.detail){
                    dispatch ('fetchTicketDetail',payload.id)
                }
              
                else if(payload.all){
                    dispatch("fetchTicket",{currentPage:payload.page,perPage:payload.perPage,start_date:payload.start_date,end_date:payload.end_date,status:payload.status, raised_by:payload.raised_by,priority:payload.priority,search_term:payload.search_term })
                }

                return response

            }catch (e) {
                commit('TICKET_REFER_ERROR', true);
            }finally {
                commit('TICKET_REFER_LOADING', false);
            }
        }
    },

    getters: {}
}
