<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Budget" title="Budget Requests" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>
                                
                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="requested_by ||  start_date || end_date || budget_category || project || selectedDepartment || selectedDesignation">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="budget_category" :options="formattedCategory"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="project" :options="formattedProject"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="requested_by" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive datatable-vue" v-if="!loading && !error" style="margin-top: 20px;">
                            <b-table show-empty stacked="md" :items="items" :fields="requestfields" :per-page="perPage">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(approved_amount)="data">
                                    <span v-if="data.item.approved_amount == null">Not Yet Approved</span>
                                    <span v-else>{{ data.item.approved_amount }}</span>
                                </template>

                                <template #cell(budget_category)="data">
                                    {{data.item.budget_category.title}}
                                </template>

                                <template #cell(project)="data"> 
                                    <span v-if="data.item.project && data.item.project.id">
                                        <router-link :to="`/project/details/${data.item.project.id}`">
                                            <span style="display: flex; flex-direction: column;">
                                                <span style="max-width: 250px;">{{ data.item.project.title }}</span>
                                                <span style="color: #ff595d;">Project ID: {{ data.item.project.project_id }}</span>
                                            </span>
                                        </router-link>
                                    </span>
                                    <span v-else>
                                        N / A
                                    </span>
                                </template>

                                <template #cell(requested_by)="data">
                                    {{ data.item.requested_by.user_name }}
                                </template>

                                <template #cell(refering_to)="data">
                                    <span>
                                        <div v-if="data.item.refering_to !== null && data.item.refering_to.length > 0">
                                            <div class="customers">
                                                <ul>
                                                    <ul v-for="(member, index) in data.item.refering_to.slice(0,4)" :key="index">
                                                        <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                        <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                    </ul>
                                                    <li class="d-inline-block mr-2" v-if="data.item.refering_to.length > 4">
                                                        <p class="f-12 pl-1" style="font-weight: 500;">+ {{ data.item.refering_to.length - 4 }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>N/A</p>
                                        </div>
                                    </span>
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'REJECTED'">
                                        <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'PENDING'">
                                        <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>

                                        <feather @click="editRequestedBudget(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Budget Approve" v-b-modal.modal-prevent-closing-budget-edit></feather>
                                        <!-- <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete"></feather> -->
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Leave Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Edit Requested Budget -->
    <b-modal id="modal-prevent-closing-budget-edit" ref="modal" title="Approve Budget" @show="resetBudgetModal" @hidden="resetBudgetModal" @ok="handleRequestOk">
        <form ref="form" @submit.stop.prevent="handleRequestSubmit">
            <b-form-group label="Budget Category" label-for="name-input" invalid-feedback="Budget category is required">
                <b-form-input id="name-input" v-model="edit_budget.category" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Details" label-for="name-input" invalid-feedback="Details is required">
                <b-form-textarea id="textarea-auto-height" v-model="edit_budget.detail_note" placeholder="Enter budget details" rows="3" max-rows="8" disabled></b-form-textarea>
            </b-form-group>
            <b-form-group label="Requested Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.budget_amount" placeholder="Enter requested amount" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Approved Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.approved_amount" placeholder="Enter approved amount"></b-form-input>
            </b-form-group>
            <b-form-group label="Status" label-for="name-input" invalid-feedback="Status is required">
                <b-form-select v-model="edit_budget.status" :options="options"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Budget Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleBudgetReplySubmit()">
                <button @click="handleBudgetReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>
</div>
</template>

<script>
import { mapState } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import { myHost } from "../../../_helpers/host";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {
    components: {
        Multiselect,
        Datepicker
    },

    data() {
        return {
            host: myHost.host,
            initialLoad: true,
            filterShow: false,
            filter: null,
            start_date: null,
            end_date: null,
            selectedDepartment: '',
            selectedDesignation: '',
            requested_by: '',
            budget_category: '',
            project: '',
            requestfields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'budget_category',
                    label: 'Budget Category',
                    sortable: true
                },
                {
                    key: 'project',
                    label: 'Project',
                    sortable: true
                },
                {
                    key: 'budget_amount',
                    label: 'Requested Amout',
                    sortable: true
                },
                {
                    key: 'approved_amount',
                    label: 'Approved Amout',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'requested_by',
                    label: 'Requested by',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Referring To',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            requests: [],
            edit_budget: {
                budget_category: '',
                category: '',
                detail_note: '',
                request_note: '',
                budget_amount: null,
                approved_amount: null,
                status: ''
            },
            options: [
                {
                    value: 'PENDING',
                    text: 'Pending'
                },
                {
                    value: 'APPROVED',
                    text: 'Approved'
                },
                {
                    value: 'REJECTED',
                    text: 'Rejected'
                }
            ],
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        },

        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/budget/budget-request/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.totalRows = this.budget_Requests_count
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/budget/budget-request/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.totalRows = this.budget_Requests_count
                });
            }
        },

        filter(newVal, oldVal) {
            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.budget_Requests_count
            });
        },

        requested_by(newVal, oldVal) {
            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.budget_Requests_count
            });
        },

        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.budget_Requests_count
                });
            } else {
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.budget_Requests_count
                });
            }
        },

        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.budget_Requests_count
                });
            } else {
                this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    search_term: this.filter,
                    requested_by: this.requested_by,
                    budget_category: this.budget_category,
                    project: this.project
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.budget_Requests_count
                });
            }
        },

        budget_category(newVal, oldVal) {
            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.budget_Requests_count
            });
        },     

        project(newVal, oldVal) {
            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.budget_Requests_count
            });
        }
    },

    computed: {
        ...mapState({
            items: state => state.budget.budgetRequestsPG,
            budget_Requests_count: state => state.budget.budget_Requests_count,
            loading: state => state.budget.submitLoading,
            error: state => state.budget.submitError,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.budget.ticket_reply,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation,
            projects: state => state.project.project,
            estimateBudgets: state => state.budget.estimateBudgets
        }),

        formattedProject() {
            return [
                {
                    value: '',
                    text: 'Select Project',
                    disabled: true
                },
                ...this.projects.map(pro => ({
                    value: pro.id,
                    text: pro.title
                }))
            ];
        },

        formattedCategory() {
            return [
                {
                    value: '',
                    text: 'Select Budget Category',
                    disabled: true
                },
                ...this.estimateBudgets.map(category => ({
                    value: category.id,
                    text: category.title
                }))
            ];
        },

        formattedEmployee() {
            return [
                {
                    value: '',
                    text: 'Select Employee (Requested By)',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [
                {
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch('budget/fetchBudget')
        this.$store.dispatch("project/fetchProject");

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage
                }
            });

            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.totalRows = this.budget_Requests_count
                this.initialLoad = false
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("budget/fetchBudgetRequestsWithPG", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(() => {
                this.totalRows = this.budget_Requests_count
                this.initialLoad = false
            });
        }
    },

    methods: {
        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.start_date = null,
            this.end_date = null,
            this.budget_category = '',
            this.project = '',
            this.selectedDepartment = '',
            this.selectedDesignation = '',
            this.requested_by = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        convertUrls(text) {
            if (text === null) {
                return '';
            }
            const urlPattern = /(https?:\/\/[^\s<]+)/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refering_to
            this.$store.dispatch("budget/fetchBudgetReply", this.my_reply.id)
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("budget/BudgetAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("budget/BudgetAddDelete", {
                id: this.my_reply.id,
                refer_id: this.temp_id,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        handleBudgetReplySubmit() {
            this.$store.dispatch("budget/addBudgetReply", {
                budget_request: this.my_reply.id,
                reply: this.my_reply.reply,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.my_reply.id = '',
                    this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        editRequestedBudget(item) {
            this.edit_budget.id = item.id
            this.edit_budget.budget_category = item.budget_category.id
            this.edit_budget.category = item.budget_category.title
            this.edit_budget.detail_note = item.detail_note
            this.edit_budget.request_note = item.request_note
            this.edit_budget.budget_amount = item.budget_amount
            this.edit_budget.approved_amount = item.approved_amount
            this.edit_budget.status = item.status
        },

        handleRequestOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRequestSubmit()
        },

        handleRequestSubmit() {
            this.$store.dispatch("budget/requestBudgetEdit", {
                id: this.edit_budget.id,
                data: this.edit_budget,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                search_term: this.filter,
                requested_by: this.requested_by,
                budget_category: this.budget_category,
                project: this.project
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = [],
                    this.member = [],
                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/budget/budget-request/list')
                    }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-budget-edit')
            })
        }
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}

.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
