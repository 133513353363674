<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Requisition" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <router-link to="/finance-and-accounts/account/requisition/create/new">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Requisition</h7>
                                </div>
                            </button>
                        </router-link>
                        <router-link to="/finance-and-accounts/account/requisition/type">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>Requisition Type</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>
                                
                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="statusSelected || start_date || end_date || selectedDepartment ||selectedDesignation || selectedEmployee">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(requisition_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`" class="link-style">
                                                {{ data.item.requisition_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(title)="data">
                                        <span>
                                            {{ data.item.title }}
                                        </span>
                                    </template>

                                    <template #cell(raised_by)="data">
                                        <div class="d-flex flex-column">
                                            <span>
                                                <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`" class="link-style">
                                                    {{ data.item.raised_by.first_name }} {{ data.item.raised_by.last_name }}
                                                </router-link>
                                            </span>
                                            <span>
                                                {{ formattedDate(data.item.created_at) }}
                                            </span>
                                        </div>
                                    </template>

                                    <template #cell(grand_total)="data">
                                        <!-- <span>
                                            {{ data.item.grand_total }}
                                        </span> -->
                                        <div class="d-flex flex-column" style="width: 150px;">
                                            <span class="font-weight-bold">Total: {{ data.item.grand_total }}</span>
                                            <span><span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }}</span>
                                            <span><span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }}</span>
                                        </div>
                                    </template>

                                    <template #cell(refering_to)="data">
                                        <span>
                                            <div v-if="data.item.refering_to !== null && data.item.refering_to.length > 0">
                                                <div class="customers">
                                                    <ul>
                                                        <ul v-for="(member, index) in data.item.refering_to.slice(0,5)" :key="index">
                                                            <li v-if="member.photo" class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" :src="host + member.photo" alt="" data-original-title=""></li>
                                                            <li v-else class="d-inline-block" v-b-tooltip.hover :title="member.full_name"><img class="img-30 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                        </ul>
                                                        <li class="d-inline-block mr-2" v-if="data.item.refering_to.length > 5">
                                                            <p class="f-12">+ {{ data.item.refering_to.length - 5 }}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>N/A</p>
                                            </div>
                                        </span>
                                    </template>

                                    <template #cell(status)="row">
                                        <!-- v-b-modal.modal-prevent-closing pill -->
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                            <b-badge variant="success" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                            <b-badge variant="primary" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                        <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                            <b-badge variant="danger" v-b-modal.modal-prevent-closing pill>{{ row.value }}</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(record)="data">
                                        <div @click="recordPayment(data)">
                                            <b-badge variant="primary" v-if="data.item.due_amount > 0" v-b-modal.modal-record>Record Payment</b-badge>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequisition(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/account/requisition/view/${data.item.id}`">
                                                <feather type="eye" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Requisition Status -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Requisition Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Requisition Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this requisition?</div>
    </b-modal>

    <!-- Record Payment -->
    <b-modal id="modal-record" ref="modal" title="Record Payment" @show="resetModal" @hidden="resetModal" @ok="handleRecordOk">
        <form ref="form" @submit.stop.prevent="handleRecordSubmit">
            <b-form-group label="Amount" label-for="name-input" invalid-feedback="Item is required">
                <b-form-input id="name-input" v-model="record.paid_amount" placeholder="Enter Amount"></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import { myHost } from '../../../_helpers/host';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requisition_id',
                    label: 'REQ-ID',
                    sortable: true
                },
                {
                    key: 'raised_by',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'grand_total',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'record',
                    label: 'Record',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            record: {
                paid_amount: '',
                requisition: '',
            },
            requisition_due: '',
            filter: null,
            filterShow: false,
            start_date: null,
            end_date: null,
            statusSelected: '',
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            statuses_list: [
                {
                    id: 1,
                    name: 'Pending',
                    value: 'PENDING'
                },
                {
                    id: 2,
                    name: 'Approved',
                    value: 'APPROVED'
                },
                {
                    id: 3,
                    name: 'Due',
                    value: 'DUE',
                }
            ],
            initialLoad: true,
            delete_value: null,
            selectedStatus: null,
            update_id: null,
            statuses: ['DUE', 'PENDING', 'APPROVED'],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    computed: {
        ...mapState({
            items: state => state.requisition.requisition,
            count: state => state.requisition.requisition_count,
            loading: state => state.requisition.submitLoading,
            error: state => state.requisition.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedOptions() {
            return [
                {
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses_list.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedEmployee() {
            return [
                {
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [
                {
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    watch: {
        filter(newVal, oldVal) {
            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        statusSelected(newVal, oldVal) {
            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        selectedEmployee(newVal, oldVal) {
            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            } else {
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            } else {
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/requisition/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    }
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/account/requisition/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    }
                });
                this.perPage = newperPage
                this.$store.dispatch("requisition/fetchRequisition", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    status: this.statusSelected,
                    search_term: this.filter,
                    raised_by: this.selectedEmployee
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("department/fetchDepartments")

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                }
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });

            this.$store.dispatch("requisition/fetchRequisition", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false
            });
        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage
                }
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("requisition/fetchRequisition", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false
            });
        }
    },

    methods: {
        clearFilter() {
            this.filterShow = false,
            this.filter = null,
            this.statusSelected ='',
            this.start_date = null,
            this.end_date = null,
            this.selectedDepartment = '',
            this.selectedDesignation = '',
            this.selectedEmployee = ''
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        formattedDate(dateString) {
            return moment(dateString).format('YYYY-MM-DD');
        },

        recordPayment(data) {
            this.record.requisition = data.item.id
            this.requisition_due = data.item.due_amount
        },

        resetModal() {
            this.record.paid_amount = ''
        },

        handleRecordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRecordSubmit()
        },

        handleRecordSubmit() {
            if (this.record.paid_amount > this.requisition_due) {
                Swal.fire({
                    title: "Error!",
                    text: 'Request amount is more than due amount!',
                    icon: "error"
                });
            } else {
                this.$store.dispatch("requisition/recordPayment", this.record).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.record.paid_amount = '',
                        this.record.requisition = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-record')
                })
            }

        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRequisition(id) {
            this.delete_value = id
        },

        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
            console.log(status, id)
        },

        resetModal() {

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("requisition/updateRequisitionStatus", {
                id: this.update_id,
                status_for_change: this.selectedStatus,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("requisition/deleteRequisition", {
                delete: this.delete_value,
                perPage: this.$route.query.perPage,
                page: this.$route.query.page,
                start_date: this.start_date,
                end_date: this.end_date,
                status: this.statusSelected,
                search_term: this.filter,
                raised_by: this.selectedEmployee
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}

.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
