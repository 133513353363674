<template>
<div>
    <Breadcrumbs main="HR" title="Time Sheet" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-wrap" style="gap: 20px;">
                                    <b-input-group class="datatable-btn" style="width: 340px;">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                        <feather type="filter"></feather>
                                        <h7>Filter</h7>
                                    </div> 

                                    <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="selectedEmployee || selectedDepartment || selectedDesignation || statusSelected">
                                        <feather type="x"></feather>
                                        <h7>Clear All</h7>  
                                    </div>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group> 
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow"> 
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <b-form-select v-model="statusSelected" :options="formattedOptions"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="timeSheetSummary" :fields="tablefields" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(username)="data">
                                        <router-link :to="`/hr/time-sheet/${data.item.user.id}`" class="link-style"> {{ data.item.user_name }} </router-link>
                                        <!-- <router-link :to="`/hr/organization/employee/details/${data.item.user.id}`" class="link-style"> {{ data.item.user_name }} </router-link> -->
                                    </template>

                                    <template #cell(department)="data">
                                        {{data.item.user.additional_info.department.name}}
                                    </template>

                                    <template #cell(designation)="data">
                                        {{data.item.user.additional_info.designation.title}}
                                    </template>

                                    <template #cell(status)="row">
                                        <span v-if="row.value == 'IN'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'OUT'">
                                            <b-badge pill variant="warning">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'absent'">
                                            <b-badge pill variant="danger" class="text-uppercase">{{ row.value }}</b-badge>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import { mapState } from 'vuex';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            filterShow: false,
            filter: null,
            statusSelected: '',
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'username',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'department',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'designation',
                    label: 'Designation',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100],
            statuses: [
                {
                    id: 1,
                    name: 'In',
                    value: 'IN'
                },
                {
                    id: 2,
                    name: 'Out',
                    value: 'OUT'
                }
            ]
        };
    },

    computed: {
        ...mapState({
            timeSheetSummary: state => state.punch.time_sheet_summary,
            loading: state => state.punch.submitLoading,
            error: state => state.punch.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation
        }),

        formattedOptions() {
            return [{
                    value: '',
                    text: 'Select Status',
                    disabled: true
                },
                ...this.statuses.map(status => ({
                    value: status.value,
                    text: status.name
                }))
            ];
        },

        formattedEmployee() {
            return [
                {
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [
                {
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")
        // this.$store.dispatch("punch/fetchTimeSheetSummary").then(() => {
        //     this.items = this.timeSheetSummary
        //     this.totalRows = this.items.length;
        // });

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            });
          
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                search_term: this.filter,
                status: this.statusSelected,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage
 
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                search_term: this.filter,
                status: this.statusSelected,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        }
    },

    watch: {
        currentPage(newCurrentPage) {
            this.$router.replace({
                path: '/hr/time-sheet',
                query: {
                    page: newCurrentPage,
                    perPage: this.perPage
                },
            });
            this.currentPage = newCurrentPage
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: newCurrentPage,
                search_term: this.filter,
                status: this.statusSelected,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.totalRows = this.count;
            });
        },

        perPage(newperPage) {
            this.$router.replace({
                path: '/hr/time-sheet',
                query: {
                    page: this.currentPage,
                    perPage: newperPage
                },
            });
            this.perPage = newperPage
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: newperPage,
                currentPage: this.currentPage,
                search_term: this.filter,
                status: this.statusSelected,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.totalRows = this.count;
            });
        },

        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: 1,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: 1,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        },

        filter(newVal, oldVal) {
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: 1,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        },

        selectedEmployee(newVal, oldVal) {
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: 1,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        },

        statusSelected(newVal, oldVal) {
            this.$store.dispatch("punch/fetchTimeSheetSummary", {
                perPage: this.perPage,
                currentPage: 1,
                status: this.statusSelected,
                search_term: this.filter,
                employee: this.selectedEmployee,
                department: this.selectedDepartment,
                designation: this.selectedDesignation
            }).then(() => {
                this.items = this.timeSheetSummary
                this.totalRows = this.items.length;
            });
        }
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        clearFilter(){
            this.filterShow = false,
            this.filter = null,
            this.statusSelected = '',
            this.selectedDepartment = '',
            this.selectedDesignation = '',
            this.selectedEmployee = ''
        }
    }
};
</script>
