<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Quotations" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="width: 100%">
                        <div style="max-width: fit-content">
                            <router-link to="/finance-and-accounts/finance/quotation/create/new">
                                <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i>
                                        <h7>Add New Quotation</h7>
                                    </div>
                                </button>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="d-flex justify-content-center align-items-center">
                            <b-col xl="6" class="d-flex flex-wrap" style="gap: 20px">
                                <b-input-group style="width: 340px;">
                                    <b-form-input v-model="filter" placeholder="Type to Search" style="box-shadow: none;"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #563EE0; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="toggleFilterShow()">
                                    <feather type="filter"></feather>
                                    <h7>Filter</h7>
                                </div>

                                <div style="display: flex; gap: 12px; align-items: center; color: white; background-color: #DC3545; padding: 2px 14px; max-width: fit-content; border-radius: 5px; cursor: pointer;" @click="clearFilter()" v-if="start_date || end_date || filter ||selectedEmployee || selectedClient || selectedProject ">
                                    <feather type="x"></feather>
                                    <h7>Clear All</h7>
                                </div>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="3" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="row mt-2" v-if="filterShow">
                            <div class="col-md-12">
                                <div class="grid-container mt-sm-0">
                                    <div class="grid-item">
                                        <datepicker placeholder="Start Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="start_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <datepicker placeholder="End Date" :typeable="true" input-class="datepicker-here form-control digits" v-model="end_date"></datepicker>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedClient" :options="formattedClient"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedProject" :options="formattedProject"></b-form-select>
                                    </div>
                                    <div class="grid-item">
                                        <b-form-select v-model="selectedDepartment" :options="formattedDepartment"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDepartment">
                                        <b-form-select v-model="selectedDesignation" :options="formattedDesignation"></b-form-select>
                                    </div>
                                    <div class="grid-item" v-if="selectedDesignation">
                                        <b-form-select v-model="selectedEmployee" :options="formattedEmployee"></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue" style="margin-top: 22px;">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :per-page="perPage">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(quotation_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/finance/quotation/${data.item.id}`" class="link-style">
                                                {{ data.item.quotation_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(subtotal)="data">
                                        <span>
                                            {{ data.item.subtotal }} {{ data.item.currency }}
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteQuotation(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/finance/quotation/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                            <feather type="plus-square" size="21px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="Generate Invoice" @click="generateInvoice(data.item.id)"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this quotation?</div>
    </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'quotation_id',
                    label: 'Quotation ID',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'quotation_date',
                    label: 'Quotation Date',
                    sortable: true
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    sortable: true
                },
                {
                    key: 'subtotal',
                    label: 'Grand Total',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            filter: null,
            delete_value: null,

            filterShow: false,
            initialLoad: true,
            start_date: null,
            end_date: null,
            selectedDepartment: '',
            selectedDesignation: '',
            selectedEmployee: '',
            selectedProject: '',
            selectedClient: '',

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 100]
        };
    },

    watch: {
        currentPage(newCurrentPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/finance/quotation/list',
                    query: {
                        page: newCurrentPage,
                        perPage: this.perPage
                    },
                });
                this.currentPage = newCurrentPage
                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: this.perPage,
                    currentPage: newCurrentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client:this.selectedClient,
                    project: this.selectedProject,
                    search_term:this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        perPage(newperPage) {
            if (!this.initialLoad) {
                this.$router.replace({
                    path: '/finance-and-accounts/finance/quotation/list',
                    query: {
                        page: this.currentPage,
                        perPage: newperPage
                    },
                });
                this.perPage = newperPage
                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: newperPage,
                    currentPage: this.currentPage,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client:this.selectedClient,
                    project: this.selectedProject,
                    search_term:this.filter
                }).then(() => {
                    this.totalRows = this.count;
                });
            }
        },

        selectedDepartment(newVal, oldVal) {
            this.$store.dispatch("department/fetchDesignationByDepartment", newVal)
        },

        selectedDesignation(newVal, oldVal) {
            this.$store.dispatch("department/fetchEmployeeByDesignation", newVal)
        },

        start_date(newVal, oldVal) {
            if (this.start_date !== null) {
                this.start_date = moment(String(this.start_date)).format("YYYY-MM-DD");

                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client:this.selectedClient,
                    project: this.selectedProject,
                    search_term:this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            } else {
                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client:this.selectedClient,
                    project: this.selectedProject,
                    search_term:this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        end_date(newVal, oldVal) {
            if (this.end_date !== null) {
                this.end_date = moment(String(this.end_date)).format("YYYY-MM-DD");
                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client: this.selectedClient,
                    project: this.selectedProject,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            } else {
                this.$store.dispatch("quotation/fetchQuotation", {
                    perPage: this.perPage,
                    currentPage: 1,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    employee: this.selectedEmployee,
                    client: this.selectedClient,
                    project: this.selectedProject,
                    search_term: this.filter
                }).then(() => {
                    this.currentPage = 1
                    this.totalRows = this.count;
                });
            }
        },

        selectedEmployee(newVal, oldVal) {
            this.$store.dispatch("quotation/fetchQuotation", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client: this.selectedClient,
                project: this.selectedProject,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        filter(newVal, oldVal) {
            this.$store.dispatch("quotation/fetchQuotation", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client: this.selectedClient,
                project: this.selectedProject,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        selectedProject(newVal, oldVal) {
            this.$store.dispatch("quotation/fetchQuotation", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client: this.selectedClient,
                project: this.selectedProject,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        },

        selectedClient(newVal, oldVal) {
            this.$store.dispatch("quotation/fetchQuotation", {
                perPage: this.perPage,
                currentPage: 1,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client: this.selectedClient,
                project: this.selectedProject,
                search_term: this.filter
            }).then(() => {
                this.currentPage = 1
                this.totalRows = this.count;
            });
        }
    },

    computed: {
        ...mapState({
            items: state => state.quotation.quotation,
            count: state => state.quotation.quotation_count,
            loading: state => state.quotation.submitLoading,
            error: state => state.quotation.submitError,
            department_list: state => state.department.departments,
            designation_list: state => state.department.designation_by_department,
            employee_list: state => state.department.employee_by_designation,
            projects: state => state.project.project,
            clients: state => state.client.client_without_pg
        }),

        formattedClient() {
            return [
                {
                    value: '',
                    text: 'Select Client',
                    disabled: true
                },
                ...this.clients.map(cli => ({
                    value: cli.user.id,
                    text: cli.company_name
                }))
            ];
        },

        formattedProject() {
            return [
                {
                    value: '',
                    text: 'Select Project',
                    disabled: true
                },
                ...this.projects.map(pro => ({
                    value: pro.id,
                    text: pro.title
                }))
            ];
        },

        formattedEmployee() {
            return [
                {
                    value: '',
                    text: 'Select Employee',
                    disabled: true
                },
                ...this.employee_list.map(emp => ({
                    value: emp.id,
                    text: emp.full_name
                }))
            ];
        },

        formattedDesignation() {
            return [
                {
                    value: '',
                    text: 'Select Designation',
                    disabled: true
                },
                ...this.designation_list.map(desg => ({
                    value: desg.id,
                    text: desg.title
                }))
            ];
        },

        formattedDepartment() {
            return [
                {
                    value: '',
                    text: 'Select Department',
                    disabled: true
                },
                ...this.department_list.map(dept => ({
                    value: dept.id,
                    text: dept.name
                }))
            ];
        }
    },

    mounted() {
        this.$store.dispatch("project/fetchProject");
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("client/fetchClient_without_pg")

        if (!this.$route.query.page || !this.$route.query.perPage) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.currentPage,
                    perPage: this.perPage,
                },
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });

            this.$store.dispatch("quotation/fetchQuotation", {
                perPage: this.perPage,
                currentPage: this.currentPage,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client:this.selectedClient,
                project: this.selectedProject,
                search_term:this.filter
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });

        } else {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    page: this.$route.query.page,
                    perPage: this.$route.query.perPage,
                },
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    throw err;
                }
            });
            this.currentPage = this.$route.query.page
            this.perPage = this.$route.query.perPage

            this.$store.dispatch("quotation/fetchQuotation", {
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client: this.selectedClient,
                project: this.selectedProject,
                search_term: this.filter
            }).then(() => {
                this.totalRows = this.count;
                this.initialLoad = false;
            });
        }
    },

    methods: {
        clearFilter() {
            this.filterShow = false,
            this.start_date = null,
            this.end_date = null,
            this.filter = null,
            this.selectedEmployee = '';
            this.selectedDepartment = '',
            this.selectedDesignation = '',
            this.selectedProject = ''
            this.selectedClient = ''   
        },

        toggleFilterShow() {
            this.filterShow = !this.filterShow
        },

        generateInvoice(id) {
            this.$router.push('/finance-and-accounts/finance/invoice/create/by-quotation/' + id)
        },

        deleteQuotation(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("quotation/deleteQuotation", {
                id: this.delete_value,
                currentPage: this.$route.query.page,
                perPage: this.$route.query.perPage,
                start_date: this.start_date,
                end_date: this.end_date,
                employee: this.selectedEmployee,
                client:this.selectedClient,
                project: this.selectedProject,
                search_term:this.filter 
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
